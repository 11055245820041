<label om-layout="vertical">
  <span>Earliest Fill Date</span>
  <omg-date-picker [formControl]="fillAfter"
                   [minDate]="minDate"
                   [maxDate]="maxDate"
                   [required]="isRequired"
                   [name]="inputName"
                   data-cy="rx-cart-item-fill-after-date-picker"></omg-date-picker>
</label>
<div *ngIf="fillAfter?.hasError"
     class="om-messages">
  <div *ngIf="fillAfter.errors?.required">Earliest fill date must be specified.</div>
  <div *ngIf="fillAfter.errors?.date">Please specify a valid date.</div>
  <div *ngIf="fillAfter.errors?.min">Earliest fill date must be after or on {{ minDate | omgDate: '4y' }}.
  </div>
  <div *ngIf="fillAfter.errors?.max">Earliest fill date must be before {{ maxDate | omgDate: '4y' }}.</div>
</div>
