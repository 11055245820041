<strong [ngClass]="{ 'critical-message': isDenial}">
  {{ medication.name }}
</strong>
<omg-medication-dea-schedule [deaSchedule]="deaSchedule"></omg-medication-dea-schedule>
<div *ngIf="denialReason?.description">
  Reason for denial: {{ denialReason.description }}
</div>
<div>
  {{ medication.dispensableTextDesc }} {{ medication.instructions | omgWrapText:65 }}
</div>
<div>
  Quantity: {{ medication.quantityDescription }},
  {{refillText}}: {{ denialReason?.description ? 0 : medication.refills }}<span *ngIf="earliestFillDate">,
    Earliest Fill Date: {{ earliestFillDate | omgDate: '2y' }}
  </span>
</div>
